import {fallbackAmountFormatter} from "./fallback-amount-formatter";

const LOCALE = "pt-BR";

const supportsLocale = Intl.NumberFormat.supportedLocalesOf(LOCALE, {
    localeMatcher: "lookup"
}).includes(LOCALE);

const localeNumberFormatter = new Intl.NumberFormat(LOCALE, {
    style: "decimal",
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

const amountFormatter = supportsLocale
    ? (value) => localeNumberFormatter.format(value)
    : (value) => fallbackAmountFormatter(value);

export function toAmountString(value) {
    return amountFormatter(value);
}

export function toCurrencyString(value) {
    return `R$ ${amountFormatter(value)}`;
}
