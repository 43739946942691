import React, { useEffect, useState } from 'react'
import { Fade } from 'react-reveal'
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor'

import prato from '../../assets/static/pratoSobre.png'

export default function Sobre() {
  const [isMobile, setMobile] = useState(false)
  useEffect(() => {
    if (window.innerWidth >= 992) {
      setMobile(false)
    } else {
      setMobile(true)
    }
  }, [window.innerWidth])

  return (
    <div className="sobre flex w-full -mt-10 md:mt-0 4xl:mt-96 flex-col md:flex-row">
      {isMobile ? (
        ''
      ) : (
        <Fade left>
          <div className="w-full flex justify-end">
            <img src={prato} alt="prato de peixe" className="pratoSobre" />
          </div>
        </Fade>
      )}
      <Fade right>
        <div className="w-full mt-36 md:mt-10 px-7 md:px-0">
          <div className="text-mainblue text-6xl marinha mb-5 text-right md:text-left">
            Sobre
          </div>
          <div className="text-mainblue w-full md:w-8/12 robotoRegular">
            Desde 1997, a Só Pesca é especializada na comercialização de
            pescados. São mais de duas décadas levando a Saúde da Natureza para
            muitas famílias brasileiras. Hoje, a indústria conta com rígidos
            processos que resultam em produtos de alta qualidade, devidamente
            aprovados pelo S.I.F. (2163).
            <br />
            <br />
            Contando com profissionais de alto nível técnico, a SÓ PESCA garante
            que chegue até o consumidor, produtos com qualidade e integridade.
            Desde a armazenagem, passando pelo controle de qualidade, até o
            transporte, a nossa missão é monitorar todas as nossas etapas
            produtivas.
          </div>
        </div>
      </Fade>
    </div>
  )
}
