import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import {
  Alerts,
  ContentComponent,
  HeaderComponent,
  LoadingComponent,
  Modal,
  TableComponent,
  TitleComponent,
} from "../../../components/componentsAdmin";
import ItemFormComponent from "./item-form-component";

import MenuComponent from "../../../components/componentsAdmin/menu";
import { deleteReceita, getReceita } from "../../../services/so-pesca";

export default function ReceitaScreen() {
  const colluns = [
    { label: "#", data: "id", order: true },
    { label: "Nome", data: "nome", order: true },
    { label: "Imagem Card", data: "imagemCard", format: "image" },
    { label: "Imagem Banner", data: "imagemBanner", format: "image" },
    { label: "Ingredientes", data: "ingredientes" },
    { label: "Preparo", data: "preparo" },
    { label: "Video", data: "video" },
    //   { label: "Status", data: "status", format: "tags", order: true },
    //   { label: "Atualizado", data: "updatedAt", format: "date" },
  ];

  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    type: "error",
    time: 3000,
    message: "",
  });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [remove, setRemove] = useState(null);
  const [selected, setSelected] = useState(null);

  useHotkeys("n", () => handleNew());

  useEffect(() => {
    setData([]);
    loadRegisters();
  }, []);

  const loadRegisters = async (id) => {
    setLoading(true);

    const result = await getReceita();
    setData(result.data);
    setLoading(false);
  };

  const handleEdit = (data) => {
    setSelected(data);
    setOpen(true);
  };

  const handleNew = () => {
    setSelected(null);
    setOpen(true);
  };

  const handleUpdate = async () => {
    setLoading(true);
    // setOpenAlert({...openAlert, open: true, message: 'Registro salvo com sucesso', type: 'success'})
    await loadRegisters();
    setOpen(false);
  };

  const modalDelete = (data) => {
    setRemove(data);
    setOpenModal(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    const result = await deleteReceita(remove.id);
    setOpenModal(false);
    if (result === false) {
      showError();
      setLoading(false);
      return false;
    }
    setRemove(null);
    setOpenAlert({
      ...openAlert,
      open: true,
      message: "Registro removido com sucesso",
      type: "success",
    });
    await loadRegisters();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
    setRemove(null);
  };

  const showError = () => {
    setOpenAlert({
      ...openAlert,
      open: true,
      message: "Houve uma falha!!!",
      type: "error",
    });
  };

  const handleHighlight = async (value, id) => {
    console.log("handleHighlight", value, id);
  };

  return (
    <div>
      <MenuComponent />
      <Alerts modal={openAlert} setOpen={setOpenAlert} time={3000} />
      <Modal
        title="Exclusão de registro"
        message={
          <>
            Tem certeza que deseja remover o registro:{" "}
            <strong>{remove?.name}</strong>
          </>
        }
        open={openModal}
        setOpen={setOpenModal}
        confirm={handleDelete}
        cancel={handleCancel}
      />
      <HeaderComponent
        title="Receitas"
        label="Novo registro"
        color="black"
        action={handleNew}
      />
      <ContentComponent>
        <div className="flex justify-between items-center">
          <TitleComponent title="Lista de receitas" />
        </div>

        {loading ? (
          <LoadingComponent />
        ) : (
          <TableComponent
            table={colluns}
            showAction={true}
            data={data}
            handleEdit={handleEdit}
            handleDelete={modalDelete}
            loading={loading}
            changeHighlight={handleHighlight}
          />
        )}
      </ContentComponent>

      {open && (
        <ItemFormComponent
          open={open}
          setOpen={() => setOpen(false)}
          data={selected}
          saveAndClose={handleUpdate}
          showError={showError}
        />
      )}
    </div>
  );
}
