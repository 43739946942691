import React from 'react'
import { Youtube } from 'react-bootstrap-icons'
import { Fade } from 'react-reveal'
import Video from "../../components/video";

export default function Page(props) {
  const urlFriendly = (value) => {
    return value == undefined
      ? ''
      : value
        .replace(/[^a-z0-9_]+/gi, '-')
        .replace(/^-|-$/g, '')
        .toLowerCase()
  }
  console.log('CARD RECEITA ::: ', props.receita)
  return (
    <Fade>
      <a
        href={`/receita?receita=${props.receita.id}&${urlFriendly(props.receita.nome)}`}
        className="flex flex-col items-center w-screen md:w-80"
      >
        <img
          src={`https://sopescadobrasil.com.br/api/upload/${props.receita.imagemCard}`}
          alt="imagem receita"
          className="card"
        ></img>
        <div className="w-72 min-h-36 h-36 -mt-44 flex justify-center items-center">
          <div className="sensations text-5xl text-mainorange text-center p-3">
            {props.receita.nome}
          </div>
        </div>
        <iframe className="video-responsive">
          {props.receita.video}
        </iframe>
      </a>
    </Fade>
  )
}
