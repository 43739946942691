import React, { useContext, useEffect, useState } from 'react'
import { AccountContext } from '../../../hooks/account-hooks'

import { useHistory } from 'react-router-dom'

import {
  Alerts,
  ButtonComponent,
  ContentComponent,
  HeaderComponent,
  InputText,
  LoadingComponent,
} from '../../../components/componentsAdmin'
import { LockClosedIcon, LoginIcon } from '@heroicons/react/solid'
import { postLogin } from '../../../services/so-pesca'

export default function LoginScreen() {
  const history = useHistory()

  const [content, setContent] = useState({
    username: '',
    password: '',
    new_password: '',
    verify_password: '',
    email: '',
  })
  const [loading, setLoading] = useState(false)
  const [newPass, setNewPass] = useState(false)
  const [openAlert, setOpenAlert] = useState({
    open: false,
    type: 'error',
    time: 3000,
    message: '',
  })

  const logged = localStorage.getItem('session@sopesca')

  useEffect(() => {
    if (logged !== undefined && logged !== null) {
      redirect()
    }
  }, [logged])

  const handleSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)

    let result = await postLogin(content)

    if (result.usuario !== undefined && result.usuario !== null) {
      localStorage.setItem('session@sopesca', result.usuario)
      redirect()
    }
    setOpenAlert({
      ...openAlert,
      open: true,
      message: 'Houve uma falha! Tente novamente.',
      type: 'error',
    })
    setLoading(false)

    // authenticate(content.username, content.password)
    //     .then(res => {
    //         redirect();
    //         // setLoading(false);
    //     })
    //     .catch(res => {
    //         setOpenAlert({
    //             ...openAlert,
    //             open: true,
    //             message: 'Houve uma falha! Tente novamente.',
    //             type: 'error'
    //         })
    //         setLoading(false);
    //     })
  }

  const redirect = (url = 'sys/receita') => {
    history.push(`/${url}`)
  }

  // const validatePassword = () => {
  //   return (
  //     content.verify_password.length > 0 &&
  //     content.verify_password !== content.new_password
  //   );
  // };

  return (
    <div>
      <Alerts modal={openAlert} setOpen={setOpenAlert} time={3000} />
      <HeaderComponent title="Login" />
      <ContentComponent>
        {loading ? (
          <LoadingComponent />
        ) : (
          <form className="w-4/12" onSubmit={handleSubmit}>
            {/* {!newPass ? ( */}
            <>
              <InputText
                name="username"
                label="Acesso"
                onChange={(e) =>
                  setContent({ ...content, username: e.target.value })
                }
                value={content.username}
                required={true}
                type="text"
              />
              {/* <InputText
                  name="password"
                  label="Senha"
                  onChange={(e) =>
                    setContent({ ...content, password: e.target.value })
                  }
                  value={content.password}
                  required={true}
                  type="password"
                /> */}

              <ButtonComponent
                type="submit"
                label="Logar"
                icon={
                  <LoginIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                }
                action={handleSubmit}
                bg="blue"
                disabled={loading}
              />
            </>
            {/* ) : (
              <>
                <h1 className="text-1xl font-bold text-gray-900">NOVA SENHA</h1>
                <div className="mb-8">
                  Para sua segurança cadastre uma nova senha:
                </div>
                <InputText
                  name="new_password"
                  label="Nova Senha"
                  onChange={(e) =>
                    setContent({ ...content, new_password: e.target.value })
                  }
                  value={content.new_password}
                  required={true}
                  type="password"
                />

                <InputText
                  name="verify_password"
                  label="Repita a senha"
                  onChange={(e) =>
                    setContent({ ...content, verify_password: e.target.value })
                  }
                  value={content.verify_password}
                  required={true}
                  type="password"
                />
                {validatePassword() && (
                  <div className="w-4/12 bg-red-100 border border-red-400 text-red-700 px-4 py-2 min-w-max rounded -mt-2 mb-4">
                    As senhas não são idênticas.
                  </div>
                )}

                <ButtonComponent
                  type="submit"
                  label="Atualizar"
                  icon={
                    <LockClosedIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  }
                  action={handleSubmit}
                  bg="blue"
                  disabled={loading}
                />
              </>
            )} */}
          </form>
        )}
      </ContentComponent>
    </div>
  )
}
