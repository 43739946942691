import styled from "styled-components";

const Content = styled.div`
	position: fixed;
	bottom: 15px;
	background-color: RGBA(0,0,0,0.9);
	border-radius: 16px;
	padding: 15px;
	z-index: 998;
	left: 50%;
  transform: translate(-50%, -10%);
  display: flex;
  color: #FFFFFF;
  font-family: "SF-Pro-Display-Medium";
  font-size: 12px;
  
  a {
  	text-decoration: underline;
    font-family: "SF-Pro-Display-Medium";
  	color: #FFFFFF;
  }
  
  button {
    background-color: #e7682a;
    font-family: "SF-Pro-Display-Medium";
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 8px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
  }
`

export default Content;
