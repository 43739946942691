import React, { useEffect, useState } from "react";

import logo from "../../assets/icons/Logo.png";
import CardReceita from "../cardReceita";
import LogoReceita from "../logoReceita";

import Carousel from "../carousel";

import { getReceita } from "../../services/so-pesca";

export default function ReceitasHome(props) {
  const [isMobile, setMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth >= 992) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  }, [window.innerWidth]);

  const [loading, setLoading] = useState(true);
  const [receitas, setReceitas] = useState([]);

  useEffect(() => {
    loadRegisters();
  }, []);

  const loadRegisters = async () => {
    setLoading(true);

    const result = await getReceita();
    console.log('RECEITAS ::: ', result);
    if (result.data.length > 3) {
      setReceitas(result.data.reverse().slice(0, 3));
    } else {
      setReceitas(result.data);
    }
    setLoading(false);
  };

  let listaMobile = [];

  const geraLista = () => {
    receitas.map((x) => {
      listaMobile.push(<CardReceita receita={x} />);
    });
  };

  return (
    <div className="receitasHome flex flex-col items-center pt-28 md:pt-16 md:pb-6">
      <LogoReceita />
      {isMobile ? (
        (geraLista(),
        (
          <div className="w-screen flex justify-center pt-20">
            <Carousel info={listaMobile} type="div" />
          </div>
        ))
      ) : (
        <div className="flex justify-around w-8/12 m-5">
          {receitas.map((x) => {
            return <CardReceita receita={x} />;
          })}
          
        </div>
      )}
    </div>
  );
}
