import React from 'react'
import { configureAnchors } from 'react-scrollable-anchor'

import Carousel from '../../components/carousel'
import banner1 from '../../assets/static/banner1.png'
import banner2 from '../../assets/static/banner1Mobile.png'

const images = [banner1, banner2]

const Receitas_Page = () => {
  return <div>123</div>
}

export default Receitas_Page
