import React, { useEffect, useState } from "react";

import seta from "../../assets/icons/setaReceitas.png";
import setaUp from "../../assets/icons/setaReceitasInvertida.png";
import CardReceita from "../cardReceita";

import { getReceita } from "../../services/so-pesca";

export default function Cardapio(props) {
  const [isMobile, setMobile] = useState(false);
  const [quantidade, setQuantidade] = useState(3);
  const [page, setPage] = useState(0);
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (window.innerWidth >= 992) {
      setMobile(false);
      setQuantidade(6);
    } else {
      setMobile(true);
      setQuantidade(3);
    }
  }, [window.innerWidth]);

  const [loading, setLoading] = useState(true);
  const [receitas, setReceitas] = useState([]);
  const [receitasAtivas, setReceitasAtivas] = useState(null)
  useEffect(() => {
    loadRegisters();
  }, []);

  const loadRegisters = async () => {
    setLoading(true);

    const result = await getReceita();
    console.log('RESULT RECEITAS ::: ', result)
    setReceitas(result.data.reverse());
    setLoading(false);
    setShow(true)
  };


  const changePage = (next) => {
    console.log("passo :::")
    if(next){
      setPage(page + 1)
    }
    else{
      setPage(page - 1)
    }
  }

  useEffect(() => {
    setReceitasAtivas(receitas.slice(page * quantidade, quantidade + page * quantidade));
  },[page, receitas])

  return (
    show && (
    <div className="cardapio w-screen flex items-center flex-col ">
      {console.log('page', page)}
      {console.log('receitasAtivas', receitasAtivas)}
      {console.log('page * quantidade', page * quantidade)}
      {console.log('quantidade + page * quantidade', quantidade + page * quantidade)}
      {page > 0 ? (
        <buttton className="" onClick={() => changePage(false)}>
          <img src={setaUp} alt="receitas anteriores" className="mt-8" />
        </buttton>
      ) : (
        ""
      )}
      <div className="w-9/12 2xl:w-8/12 3xl:w-7/12 4xl:w-6/12 pt-20 pb-10 flex justify-around items-baseline flex-wrap">
         { receitasAtivas.map((x, index) => {
              return (
                <>
                  <CardReceita receita={x} />
                </>
              );
             })}
      </div>
      {receitas.length > (quantidade + page * quantidade) ? (
        <div className="flex mb-10" style={{zIndex: '999'}}>
          <buttton className="" onClick={() => changePage(true)}>
            <img src={seta} alt="seta mais receitas" className="" />
          </buttton>
        </div>
        
      ) : (
        ""
      )}
    </div>
    )
  );
}
