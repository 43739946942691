import React from "react";
import { CheckIcon } from "@heroicons/react/solid";

export default function MenuComponent({ title, label, action = null }) {
  return (
    <header className="bg-gray-100 shadow">
      <div className="max-w-7xl mx-auto py-6 px-4 flex justify-between">
        <h1 className="text-3xl font-bold text-gray-900">{title}</h1>

        {action && (
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={action}
          >
            <CheckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />{" "}
            {label}
          </button>
        )}
      </div>
    </header>
  );
}
