import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";

import elipse from "../../assets/static/Elipse1.png";
import texto from "../../assets/static/texto-pratica.png";
import camarao from "../../assets/static/camarao.png";
import manjuba from "../../assets/static/manjuba.png";
import bandejas from "../../assets/static/bandejasPratica.png";

export default function Pratica(props) {
  const [isMobile, setMobile] = useState(false);
  const [listImages, setListImages] = useState([]);
  useEffect(() => {
    if (window.innerWidth >= 992) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  }, [window.innerWidth]);

  return (
    <div className="pratica -mt-40 px-7 md:px-2 md:pt-40 w-screen bg-green-300 flex flex-col items-center">
      <Fade right>
        <div className="w-full md:w-1/2">
          <div className="mt-10 md:mt-16 md:mt-10 mr-80 text-3xl md:text-4xl 3xl:text-6xl marinha w-full">
            Experimente o sabor
            <br />
            da Praticidade!
          </div>
          {/* <div>
            <img src={bandejas} className="w-7/12" />
            <img
              src={texto}
              alt="linha pratica"
              className="w-3/4 md:w-5/12 h-full mt-4 md:mt-20 2xl:mt-32 -ml-16 md:-ml-20 2xl:-ml-28 z-2 relative"
            />
          </div> */}
          <div className="flex items-center md:items-start md:justify-start flex-col-reverse md:flex-row">
            <img
              src={camarao}
              alt="bandeja de camarões"
              className="-ml-24 md:-ml-24 2xl:-ml-36 w-3/4 md:w-6/12"
            />
            <img
              src={texto}
              alt="linha pratica"
              className="w-3/4 md:w-5/12 h-full mt-4 md:mt-20 2xl:mt-32 -ml-16 md:-ml-20 2xl:-ml-28 z-2 relative"
            />
          </div>
          <div className="flex justify-start">
            <img
              src={elipse}
              alt=""
              className="-ml-14 md:-ml-24 2xl:-ml-36 w-3/4 md:w-5/12 h-full -mt-24 md:-mt-32 3xl:-mt-56"
            />
            <img
              src={manjuba}
              alt="bandeja de manjuba"
              className="w-3/4 md:w-6/12 h-full -ml-24 md:-ml-36 2xl:-ml-44 -mt-32 md:-mt-44 2xl:-mt-52 3xl:-mt-64"
            />
          </div>
        </div>
      </Fade>
    </div>
  );
}
