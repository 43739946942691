import React from 'react'
import { Fade } from 'react-reveal'

import logo from '../../assets/icons/Logo.png'
import whatsapp from '../../assets/icons/icon-whatsapp.png'
import youtube from '../../assets/icons/icon-youtube.png'
import instagran from '../../assets/icons/icon-instagran.png'
import face from '../../assets/icons/icon-face.png'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from 'store/actions'

export default function Page(props) {
  const dispatch = useDispatch()
  const arrayLinks = [
    {
      nome: 'A Só Pesca',
      link: '/',
    },
    {
      nome: 'Produtos',
      link: '/produtos',
    },
    {
      nome: 'Onde Encontrar',
      link: '/onde-encontrar',
    },
    {
      nome: 'Receitas e Dicas',
      link: '/lista-receitas',
    },
    {
      nome: 'Política de Privacidade',
      link: '/',
    },
  ]

  return (
    <div>
      <div className="w-full h-20 2xl:h-24 rodapetop -mb-1 -mt-20 md:-mt-10"></div>
      <div className="w-full flex flex-col items-center bg-mainblue pt-5 pb-5">
        <Fade top>
          <div className="logo">
            <img
              src={logo}
              alt="logo"
              className="w-10/12 md:w-full mb-10"
            ></img>
          </div>
        </Fade>
        <Fade>
          <div className="flex flex-row mb-10 ">
            <a
              href="https://www.instagram.com/so.pesca/"
              target="blank"
              className="w-8 h-8 mx-1.5 ml-4"
            >
              <img alt="instagram" src={instagran}></img>
            </a>
            <a
              href="https://www.facebook.com/sopescabrasil"
              target="blank"
              className="w-8 h-8 mx-1.5"
            >
              <img alt="facebook" src={face}></img>
            </a>
            <a
              href="https://www.youtube.com/channel/UCHcqWhRgoeM8SoPSdlyv9jw"
              target="blank"
              className="w-10 h-8 mx-1.5"
            >
              <img alt="youtube" src={youtube} className="h-full w-full"></img>
            </a>
            <a
              href="https://wa.me/c/5515996620757"
              target="blank"
              className="w-8 h-8 mx-1.5"
            >
              <img alt="whatsapp" src={whatsapp}></img>
            </a>
          </div>
        </Fade>

        <div className="flex flex-col md:flex-row mb-10">
          <Fade left>
            <a
              href="https://wa.me/c/5515996620757"
              target="blank"
              className="w-40 h-10 bg-white text-mainblue rounded-lg mb-4 md:mb-0 md:mr-2 flex items-center justify-center"
            >
              <strong>Seja nosso Cliente</strong>
            </a>
          </Fade>
          <Fade right>
            <a
              href="/trabalhe-conosco"
              className="w-40 h-10 bg-white text-mainblue rounded-lg md:ml-2 flex items-center justify-center"
            >
              <strong>Trabalhe Conosco</strong>
            </a>
          </Fade>
        </div>
        <div className="flex flex-col h-60 md:h-16 md:flex-row items-center justify-around w-1/2">
          <Fade>
            <a href={arrayLinks[0].link} className="text-sm">
              <strong>A Só Pesca</strong>
            </a>
          </Fade>
          <div className="w-1 h-1 rounded-full bg-red-500"></div>
          <Fade>
            <a href={arrayLinks[1].link} className="text-sm">
              <strong>Produtos</strong>
            </a>
          </Fade>
          <div className="w-1 h-1 rounded-full bg-red-500"></div>
          <Fade>
            <a href={arrayLinks[2].link} className="text-sm">
              <strong>Onde Encontrar</strong>
            </a>
          </Fade>
          <div className="w-1 h-1 rounded-full bg-red-500"></div>
          <Fade>
            <a href={arrayLinks[3].link} className="text-sm">
              <strong>Receitas e Dicas</strong>
            </a>
          </Fade>
          {/* <div className="w-1 h-1 rounded-full bg-red-500"></div>
          <Fade>
            <a onClick={() => dispatch(Actions.toggle_modal('politica'))} className="text-sm">
              <strong>Política de Privacidade</strong>
            </a>
          </Fade> */}
        </div>

        <div className="text-xs mt-14">
          2022 - Todos os Direitos Reservados | Desenvolvido por ÉPICA CREATIVE
        </div>
      </div>
    </div>
  )
}
