export const modelItem = {
    "name": "",
    "description": "",
    "timeFoPreparation": null,
    "price": "",
    "averageReview": "5",
    "isVegan": false,
    "isVegetarian": false,
    "glutenFree": false,
    "pictureUrl": "",
    "createdAt": "",
    "updatedAt": "",
    "restaurantId": 0,
    "order": 0,
    "isHighlighted": false
}