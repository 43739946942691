import React from "react";

export default function BannerProdutos(props) {
  return (
    <div className="flex md:flex-row-reverse justify-center md:justify-start items-center pt-12 md:pt-24">
      <div className="bannerProdutos w-screen mt-20 md:mt-0">
        <div className="text-mainorange text-3xl md:text-5xl text-center md:text-center mb-44 md:mb-0 w-full md:w-5/5 md:p-10 md:mt-60 md:ml-80 md:pl-60">
          <div className="robotoRegular mt-2 md:mt-4">
            Seja do rio ou do mar
          </div>
          <div className="robotoRegular md:mt-4">
            a Só Pesca entrega
          </div>
          <div className="robotoBold md:mt-4">o melhor para você!</div>
        </div>
      </div>
    </div>
  );
}
