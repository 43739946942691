import React from "react";

const ButtonComponent = ({
  label,
  action,
  color = "white",
  icon,
  classname,
  bg = "indigo",
  border = "transparent",
  disabled,
  type = "button",
}) => {
  const opacity = disabled ? "opacity-30" : "opacity-100";

  return (
    <button
      type={type}
      onClick={action}
      disabled={disabled}
      className={`inline-flex items-center px-4 py-2 border border-${border} rounded-md shadow-sm text-sm font-medium text-${color} bg-${bg}-600 hover:bg-${bg}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${bg}-500 ${classname} ${opacity}`}
    >
      {icon}
      {label}
    </button>
  );
};

export default ButtonComponent;
