import React from "react";
import { configureAnchors } from "react-scrollable-anchor";

import {
  Menu,
  BannerProdutos,
  ConteudoProdutos,
  Rodape,
} from "../../components";

const Receitas_Page = () => {
  configureAnchors({ offset: -60, scrollDuration: 2000 });

  return (
    <div>
      <Menu />
      <BannerProdutos />
      <ConteudoProdutos />
      <Rodape />
    </div>
  );
};

export default Receitas_Page;
