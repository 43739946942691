import React, { useEffect, useState } from 'react'

import { Content } from './style'
import * as Icon from 'react-bootstrap-icons'

import logo from '../../assets/icons/logo_azul.png'
import lupa from '../../assets/icons/lupa.png'
import Header from '../header'

let limit_scroll = 300
let limit_scroll_header = 250

const MenuSite = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [isMobile, setMobile] = useState(false)
  const [active, setActive] = useState('#')
  const [scroll, setScroll] = useState(0)

  useEffect(() => {
    if (window.innerWidth >= 992) {
      setShowMenu(true)
      setMobile(false)
    } else {
      setMobile(true)
      limit_scroll = 50
    }
  }, [window.innerWidth])

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => {
      window.removeEventListener('scroll', listenToScroll)
    }
  }, [])

  function listenToScroll() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    setScroll(winScroll)
  }

  const updateMenu = () => {
    const { innerWidth: width } = window
    if (width >= 992) {
      setShowMenu(true)
    } else {
      setShowMenu(!showMenu)
    }
  }

  const clickMenu = (link) => {
    setActive(link)
    if (isMobile) {
      setShowMenu(false)
    }
  }

  return (
    <Content
      className={`navbar flex flex-col justify-center items-center fixed-top  ${
        scroll > limit_scroll ? 'bg-active' : ''
      }`}
    >
      {scroll < limit_scroll_header ? <Header /> : ''}
      <div className=" px-3 md:px-2 flex w-screen justify-around flex-col md:flex-row bg-white">
        <div
          className={`menu-logo w-full flex lg:w-3/12 ${
            scroll > limit_scroll ? 'menu-scroll' : 'menu-top'
          }`}
        >
          <a
            href="/"
            onClick={() => clickMenu('home')}
            aria-label="Product"
            className="w-1/2 m-auto md:m-0 md:w-full"
          >
            <img
              src={logo}
              alt="SoPesca"
              className={scroll > limit_scroll ? 'scroll' : ''}
              id="logo"
            />
          </a>
          <div className="block lg:hidden">
            <button
              type="button"
              className="menu-mobile"
              onClick={() => updateMenu()}
            >
              {!showMenu ? (
                <Icon.List size={32} color={'#003F7F'} />
              ) : (
                <Icon.X size={32} color={'#003F7F'} />
              )}
            </button>
          </div>
        </div>
        <div
          className={`w-full lg:w-1/2 xl:w-1/2 lg:ml-40 justify-around menu-nav ${
            showMenu ? 'show-menu' : 'hide-menu'
          }`}
        >
          <a
            onClick={() => clickMenu('home')}
            className="link text-2xl"
            href="/"
          >
            <strong>A Só Pesca</strong>
          </a>
          <a
            onClick={() => clickMenu('produtos')}
            className="link"
            href="/produtos"
          >
            <strong>Produtos</strong>
          </a>
          <a
            onClick={() => clickMenu('ondeEncontrar')}
            className="link"
            href="/onde-encontrar"
          >
            <strong>Onde Encontrar</strong>
          </a>
          <a
            onClick={() => clickMenu('receitas')}
            className="link"
            href="/lista-receitas"
          >
            <strong>Receitas e Dicas</strong>
          </a>
          {/* <buttom className="flex items-center px-2">
            <img src={lupa} alt="pesquisar" className="w-1/12 md:w-10/12" />
          </buttom> */}
        </div>
      </div>
      <div className="w-screen toldo -mt-1"></div>
    </Content>
  )
}

export default MenuSite
