import styled from "styled-components";

const Content = styled.div`
  font-family: "SF-Pro-Display-Medium";
  font-size: 12px;
  z-index: 999;
  
    button {
    background-color: #d3d3d3;
    font-family: "SF-Pro-Display-Medium";
    text-transform: uppercase;
    color: #000000;
    border-radius: 8px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  },
`

const Title = styled.div`
  font-family: "SF-Pro-Display-Medium";
  font-size: 18px;
`

export {Content, Title};
