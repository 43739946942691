import React from "react";
import { Fade } from "react-reveal";

import produto1 from "../../assets/static/produto5.png";
import produto2 from "../../assets/static/produto2.png";
import produto3 from "../../assets/static/produto3.png";
import produto4 from "../../assets/static/produto4.png";
import catalogo from "../../assets/static/Catalogo-So-pesca-1.pdf";

export default function Mapa() {
  return (
    <div className="mapa w-screen flex flex-col items-center pt-4 md:pt-8 pb-8 md:pb-14">
      <div className="robotoRegular text-mainblue text-xl px-7 md:px-32 text-center mb-10">
        Dentro dos mais rigorosos padrões de qualidade, a Só Pesca preza pelo
        diferencial de cada consumidor, pela praticidade do dia a dia, e claro
        que pelo sabor e saúde para sua família.
        <br />
        <br /> Seja no almoço do dia a dia ou no churrasco com os amigos... A
        Saúde da Natureza sempre presente em sua mesa... Conheça um pouco sobre
        cada característica das nossas proteínas:
      </div>
      <div className="flex flex-row justify-center items-center flex-wrap w-full">
        <Fade>
          <div className="flex flex-col items-center justify-around w-44 h-56">
            <div className="w-44 h-56 flex justify-center items-center">
              <img className="iconeProduto" src={produto1}></img>
            </div>
            <div className="text-mainblue robotoRegular -mt-6">Dia a Dia</div>
          </div>
        </Fade>
        <Fade>
          <div className="flex flex-col items-center justify-around w-44 h-56">
            <div className="w-44 h-56 flex justify-center items-center">
              <img className="iconeProduto" src={produto2}></img>
            </div>
            <div className="text-mainblue robotoRegular">Prática</div>
          </div>
        </Fade>
        <Fade>
          <div className="flex flex-col items-center justify-around w-44 h-56">
            <div className="w-44 h-56 flex justify-center items-center">
              <img className="iconeProduto" src={produto3}></img>
            </div>
            <div className="text-mainblue robotoRegular">Churrasco</div>
          </div>
        </Fade>
        <Fade>
          <div className="flex flex-col items-center justify-around w-44 h-56">
            <div className="w-44 h-56 flex justify-center items-center">
              <img className="iconeProduto" src={produto4}></img>
            </div>
            <div className="text-mainblue robotoRegular">Amazônia</div>
          </div>
        </Fade>
      </div>
      <div className="robotoRegular text-mainblue text-center mt-8 mb-12 text-lg px-7" style={{fontWeight: 'bold'}}>
        Acesse nosso catálogo completo 
      </div>
      <div>
        <a
          href={catalogo}
          //download
          target="_blank"
          className="robotoBold text-2xl bg-mainorange md:mb-0 py-3 px-6 rounded-full"
          rel="noreferrer"
        >
          CATÁLOGO SÓ PESCA
        </a>
      </div>
    </div>
  );
}
