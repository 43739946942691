import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { ButtonComponent, LoadingComponent } from "../../index";
import {
  postUploadImage,
  getUploadImage,
} from "../../../../services/so-pesca/digital-items-service";
import { XIcon } from "@heroicons/react/solid";

export default function Upload({
  name,
  label,
  onUpload,
  showError,
  value = "",
}) {
  const [picture, setPicture] = useState(value);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPicture(value);
  }, [value]);

  const handleUpload = async (e) => {
    console.log("handleUpload file: ", e.target.files[0]);

    setLoading(true);

    const data = new FormData();
    data.append("file", e.target.files[0], e.target.files[0].name);

    const result = await postUploadImage(data);
    if (result === false) {
      showError();
      setLoading(false);
      return false;
    }
    console.log("result", result);

    let image = await getUploadImage(result.arquivo);
    console.log("image", image);

    setPicture(result.arquivo);
    onUpload(result.arquivo);
    setLoading(false);
  };

  const handleRemove = () => {
    setPicture("");
    onUpload("");
  };

  return (
    <div className={`mb-4 row flex justify-between items-center align-middle`}>
      <div className={`w-10/12`}>
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          {label}
        </label>

        {loading ? (
          <LoadingComponent />
        ) : (
          <div className="border border-dashed border-gray-500 relative rounded">
            <input
              type="file"
              className="cursor-pointer relative block opacity-0 w-full h-full px-4 py-8 z-50"
              onChange={handleUpload}
            />
            <div className="text-center py-8 absolute top-0 right-0 left-0 m-auto text-gray-900">
              <h4>
                Arraste um arquivo para esta área ou clique e selecione um
                arquivo.
              </h4>
            </div>
          </div>
        )}
      </div>

      <div className="w-2/12 flex flex-col items-center align-middle justify-center">
        <Tooltip
          className="p-0"
          title={<img src={`https://sopescadobrasil.com.br/api/upload/${picture}`} alt="" width="350" />}
          placement="left"
        >
          <div className="w-full flex justify-center mt-4">
            <img
              className="inline-block h-20 w-20 rounded-lg ring-2 ring-white"
              src={`https://sopescadobrasil.com.br/api/upload/${picture}`}
              alt=""
            />
          </div>
        </Tooltip>
        <ButtonComponent
          icon={<XIcon className="h-4 w-4" aria-hidden="true" />}
          action={handleRemove}
          bg="white"
          color="black"
          border="black-500"
          disabled={loading}
        />
      </div>
    </div>
  );
}
