import React from 'react'
import { Fade } from 'react-reveal'

export default function Trabalhe() {
  return (
    <Fade bottom>
      <div className="trabalhe w-screen flex flex-col items-center pt-4 md:pt-4 pb-8 md:pb-14">
        <div className="robotoRegular text-mainblue text-xl px-7 md:px-10 text-center mb-10">
          Nossa gestão é transparente e valoriza o bom trabalho. Praticamos e
          acreditamos
          <br /> nas ações que demonstram respeito e conhecimento aos nossos
          colaboradores.
          <br />
          <br />
          Servimos muitas famílias pelo Brasil há 24 anos, por isso prezamos
          pela qualidade,
          <br /> pela cultura de equipe e pela harmonia no ambiente de trabalho.
        </div>
        <div>
          <buttom className="robotoBold text-lg md:text-2xl bg-mainorange md:mb-0 py-2 md:py-3 px-4 md:px-6 rounded-full">
            TRABALHE COM A SÓ PESCA
          </buttom>
        </div>
        <div>
          <div className="robotoBold text-mainblue text-center mt-8 text-lg px-7">
            Quer fazer parte da nossa equipe?
          </div>
          <div className="robotoRegular text-mainblue text-center mb-12 text-lg px-7">
            •Respeite e valorize a diversidade;
            <br />
            •Tenha iniciativa e resiliência estratégica;
            <br />
            •Cuide da saúde corporal e mental;
            <br />
            •Respeite e proteja o nosso meio ambiente;
            <br />
            •Proatividade e a chave do sucesso! Sejamos um time!
            <br />
          </div>
        </div>
      </div>
    </Fade>
  )
}
