import React, {useState} from "react";
import { Fade } from "react-reveal";

import cuscuz from "../../assets/static/cuscuz.png";

export default function LogoReceita(props) {
  const [isMobile, setMobile] = useState(window.innerWidth >= 768 ? false : true);
  return (
    <Fade top>
      <div className="flex flex-col items-center">
        {isMobile ?
          <>
            <div className="text-5xl text-mainorange bernier pt-10">
              receitas e dicas
            </div>
            <div className="text-6xl text-mainblue sensations">
              #Saúde Na Mesa
            </div>
          </>
          :
          <>
            <div className="text-8xl text-mainorange bernier 2xl:mt-40 xl:mt-20 md:mt-20">
              receitas e dicas
            </div>
            <div className="text-9xl text-mainblue sensations" style={{marginTop: '-50px'}}>
              #Saúde Na Mesa
            </div>
          </>
        }
        
      </div>
    </Fade>
  );
}
