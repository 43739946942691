import React from "react";

import macarrao from "../../assets/static/macarrao_camarao.png";

export default function BannerReceita(props) {
  console.log(props);
  return (
    <div className="bannerReceita w-screen flex flex-col md:flex-row justify-center items-center bg-mainblue pt-24">
      <div className="w-full md:w-1/3 sensations text-5xl md:text-7xl 2xl:text-8xl 3xl:text-9xl mt-14 md:mt-32 leading-normal text-center">
        {props.receita?.nome}
        {/* Massa Especial com Camarões Só Pesca */}
      </div>
      <div className="w-full md:w-1/2 md:mt-32 flex justify-center ">
        <img
          src={`https://sopescadobrasil.com.br/api/upload/${props.receita?.imagemBanner}`}
          className="w-10/12"
        />
      </div>
    </div>
  );
}
