import axios from "axios";

export const getBanner = async () => {
  let result = [];

  await axios
    .get(`https://sopescadobrasil.com.br/api/banner/list`)
    .then((res) => {
      console.log("getBanner result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("getBanner catch", error);
      result = { error: true };
    });
  return result;
};

export const getBannerId = async (id) => {
  let result = [];

  await axios
    .get(`https://sopescadobrasil.com.br/api/banner/${id}`)
    .then((res) => {
      console.log("getBanner result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("getBanner catch", error);
      result = { error: true };
    });
  return result;
};

export const postBanner = async (data) => {
  // const _config = config();
  let result = [];
  await axios
    .post(`https://sopescadobrasil.com.br/api/banner/`, data)
    .then((res) => {
      console.log("postBanner result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("postBanner catch", error);
      result = { error: true };
    });
  return result;
};

export const putBanner = async (data) => {
  // const _config = config();
  let result = [];
  await axios
    .post(`https://sopescadobrasil.com.br/api/banner/update`, data)
    .then((res) => {
      console.log("putBanner result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("putBanner catch", error);
      result = { error: true };
    });
  return result;
};

export const deleteBanner = async (id) => {
  let result = [];
  await axios
    .delete(`https://sopescadobrasil.com.br/api/banner/${id}`)
    .then((res) => {
      console.log("deleteBanner result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("deleteBanner catch", error);
      result = { error: true };
    });
  return result;
};

export const getReceita = async () => {
  let result = [];

  await axios
    .get(`https://sopescadobrasil.com.br/api/receita/list`)
    .then((res) => {
      console.log("getReceita result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("getReceita catch", error);
      result = { error: true };
    });
  return result;
};

export const getReceitaId = async (id) => {
  let result = [];

  await axios
    .get(`https://sopescadobrasil.com.br/api/receita/id/${id}`)
    .then((res) => {
      console.log("getReceita result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("getReceita catch", error);
      result = { error: true };
    });
  return result;
};

export const postReceita = async (data) => {
  // const _config = config();
  let result = [];
  await axios
    .post(`https://sopescadobrasil.com.br/api/receita/`, data)
    .then((res) => {
      console.log("postReceita result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("postReceita catch", error);
      result = { error: true };
    });
  return result;
};

export const putReceita = async (data) => {
  // const _config = config();
  let result = [];
  await axios
    .post(`https://sopescadobrasil.com.br/api/receita/update`, data)
    .then((res) => {
      console.log("putReceita result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("putReceita catch", error);
      result = { error: true };
    });
  return result;
};

export const deleteReceita = async (id) => {
  let result = [];
  await axios
    .delete(`https://sopescadobrasil.com.br/api/receita/${id}`)
    .then((res) => {
      console.log("deleteReceita result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("deleteReceita catch", error);
      result = { error: true };
    });
  return result;
};

export const getCv = async () => {
  let result = [];

  await axios
    .get(`https://sopescadobrasil.com.br/api/cv/list`)
    .then((res) => {
      console.log("getCv result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("getCv catch", error);
      result = { error: true };
    });
  return result;
};

export const getCvId = async (id) => {
  let result = [];

  await axios
    .get(`https://sopescadobrasil.com.br/api/cv/id/${id}`)
    .then((res) => {
      console.log("getCv result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("getCv catch", error);
      result = { error: true };
    });
  return result;
};

export const postCv = async (data) => {
  // const _config = config();
  let result = [];
  await axios
    .post(`https://sopescadobrasil.com.br/api/cv/`, data)
    .then((res) => {
      console.log("postCv result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("postCv catch", error);
      result = { error: true };
    });
  return result;
};

export const putCv = async (data) => {
  // const _config = config();
  let result = [];
  await axios
    .post(`https://sopescadobrasil.com.br/api/cv/update`, data)
    .then((res) => {
      console.log("putCv result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("putCv catch", error);
      result = { error: true };
    });
  return result;
};

export const deleteCv = async (id) => {
  let result = [];
  await axios
    .delete(`https://sopescadobrasil.com.br/api/cv/${id}`)
    .then((res) => {
      console.log("deleteCv result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("deleteCv catch", error);
      result = { error: true };
    });
  return result;
};

export const postUploadImage = async (data) => {
  let result = [];
  await axios
    .post(`https://sopescadobrasil.com.br/api/upload`, data)
    .then((res) => {
      console.log("postUploadImage result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("postUploadImage catch", error);
      result = { error: true };
    });
  return result;
};

export const getUploadImage = async (file) => {
  let result = [];
  await axios
    .get(`https://sopescadobrasil.com.br/api/upload/${file}`)
    .then((res) => {
      console.log("getUploadImage result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("getUploadImage catch", error);
      result = { error: true };
    });
  return result;
};

export const postLogin = async (data) => {
  // const _config = config();
  console.log(data);
  let result = [];
  await axios
    .post(`https://sopescadobrasil.com.br/api/login/`, data)
    .then((res) => {
      console.log("postLogin result", res.data);
      result = { ...res.data, error: false };
    })
    .catch((error) => {
      console.log("postLogin catch", error);
      result = { error: true };
    });
  return result;
};
