import React, { Fragment, useState, useContext } from 'react'

import { Disclosure, Menu, Transition } from '@headlessui/react'

import { useHistory } from 'react-router-dom'

import { Alerts } from '../index'
import { AccountContext } from '../../../hooks/account-hooks'

import logo from '../../../assets/icons/logo_azul.png'

const navigation = [
  { name: 'Receitas', href: '/sys/receita', current: false },
  { name: 'Banners', href: '/sys/banner', current: false },
  { name: 'Curriculos', href: '/sys/cvs', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function MenuComponent() {
  const history = useHistory()

  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openAlert, setOpenAlert] = useState({
    open: false,
    type: 'error',
    time: 3000,
    message: '',
  })

  const usuario = localStorage.getItem('session@sopesca')

  // const { logged, username } = useContext(AccountContext);

  // console.log('logged', logged);

  const initialNames = (value) => {
    let name = value
    // console.log('initialNames', value)
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

    let initials = [...name.matchAll(rgx)] || []

    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase()

    // console.log(initials);
    return initials
  }

  const link = (to) => {
    history.push(to)
  }

  return (
    <>
      <Alerts modal={openAlert} setOpen={setOpenAlert} time={3000} />

      <Disclosure as="nav" className="bg-white">
        {({ open }) => (
          <div className="bg-white-100">
            <div className="max-w-7xl mx-auto px-2">
              <div className="relative flex items-center justify-between h-16">
                <div className="flex-1 flex items-center justify-between">
                  <div className="flex-shrink-0 flex items-center">
                    <a href="/">
                      <img
                        className="block lg:hidden h-10 w-auto"
                        src={logo}
                        alt="Workflow"
                      />
                    </a>
                    <a href="/">
                      <img
                        className="hidden lg:block h-10 w-auto"
                        src={logo}
                        alt="Workflow"
                      />
                    </a>
                  </div>

                  {/* {logged && ( */}
                  <>
                    <div className="hidden sm:block sm:ml-6">
                      <div className="flex space-x-4">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            onClick={() => link(item.href)}
                            className={classNames(
                              item.current
                                ? 'bg-dark-900 text-black'
                                : 'text-gray-500 hover:bg-dark-700 hover:text-black',
                              'px-3 py-2 rounded-md text-sm font-medium  cursor-pointer',
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </>
                  {/* )} */}
                </div>

                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <Menu as="div" className="ml-3 relative">
                    {/* {logged && ( */}
                    <div>
                      <Menu.Button className="h-8 w-8 rounded-full bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white flex justify-center content-center items-center text-white">
                        {/* <div>{initialNames(username)}</div> */}
                      </Menu.Button>
                    </div>
                    {/* )} */}
                    {/* {logged && ( */}
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={() => link('/sys/logout')}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700 cursor-pointer',
                              )}
                            >
                              Sair
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                    {/* )} */}
                  </Menu>
                  <div className="text-gray-900 ml-2">{usuario}</div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-dark-900 text-black'
                        : 'text-gray-500 hover:bg-dark-700 hover:text-black',
                      'block px-3 py-2 rounded-md text-base font-medium',
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </>
  )
}
