import React from "react";

export default function BannerProdutos(props) {
  return(
    <div className="flex ">
      <div className="flex-auto w-screen bannerTrabalhe 2xl:mt-10 xl:mt-10 md:mt-10"></div>
    </div>
  ) 
  
}
