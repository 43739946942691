import React, {Component, useEffect} from "react";

import {Provider, useDispatch, useSelector} from "react-redux";
import store from "store";
import Routes from "./Routes";

function App() {

    return (
        <Provider store={store}>
            <Routes/>
        </Provider>
    );
}

export default App;