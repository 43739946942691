import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

// import { AccountContext } from "../../../hooks/account-hooks";

export default function LogoutScreen() {
  const history = useHistory()

  // const { logout } = useContext(AccountContext);

  const removeOauth = () => {
    localStorage.removeItem('session@sopesca')
    history.push('/sys/login')
  }

  return <div>{removeOauth()}</div>
}
