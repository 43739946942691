import React, {useEffect} from 'react';

let timerClose = null;

export default function Alerts({time = 2000, modal, setOpen}) {

    const typeTitle = () => {
        switch (modal.type) {
            case 'error':
                return "Erro";
            case 'alert':
                return "Alerta";
            default:
                return "Sucesso";
        }
    }

    const typeAlert = () => {
        switch (modal.type) {
            case 'error':
                return "red";
            case 'alert':
                return "yellow";
            default:
                return "green";
        }
    }

    useEffect(() => {
        closeAlert();
    }, [modal.open]);

    const closeAlert = () => {
        clearTimeout(timerClose);
        timerClose = setTimeout(() => setOpen({...modal, open: false}), time);
    }

    const btnClose = () => {
        clearTimeout(timerClose);
        setOpen(false)
    }

    return modal.open ?
        <div
            className={`bg-${typeAlert()}-100 border border-${typeAlert()}-400 text-${typeAlert()}-700 px-4 py-3 min-w-max rounded fixed bottom-4 alerts`}
            role="alert">
            <strong className="font-bold">{typeTitle()}</strong>
            <span className="block sm:inline ml-4">{modal.message}</span>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={btnClose}>
                <svg className={`fill-current h-6 w-6 text-${typeAlert()}-500`} role="button" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20">
                    <title>Close</title>
                    <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
            </span>
        </div> : <></>
}