import React from "react";

import setaEsquerda from "../../assets/icons/seta-esquerda.png";
import setaDireita from "../../assets/icons/seta-direita.png";

export default function Carousel({ info }) {
  const [currentInfo, setCurrentInfo] = React.useState(0);

  const refs = info.reduce((acc, val, i) => {
    acc[i] = React.createRef();
    return acc;
  }, {});

  const scrollToInfo = (i) => {
    setCurrentInfo(i);

    refs[i].current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const totalInfo = info.length;

  const nextInfo = () => {
    if (currentInfo >= totalInfo - 1) {
      scrollToInfo(0);
    } else {
      scrollToInfo(currentInfo + 1);
    }
  };

  const previousInfo = () => {
    if (currentInfo === 0) {
      scrollToInfo(totalInfo - 1);
    } else {
      scrollToInfo(currentInfo - 1);
    }
  };

  // Tailwind styles. Most importantly notice position absolute, this will sit relative to the carousel's outer div.
  const arrowStyle =
    "absolute text-white text-2xl z-10 h-10 w-10 rounded-full opacity-75 flex items-center justify-center";

  // Let's create dynamic buttons. It can be either left or right. Using
  // isLeft boolean we can determine which side we'll be rendering our button
  // as well as change its position and content.
  const sliderControl = (isLeft) => (
    <button
      type="button"
      onClick={isLeft ? previousInfo : nextInfo}
      className={`${arrowStyle} ${isLeft ? "left-2" : "right-2"}`}
      style={{ top: "40%" }}
    >
      <span role="img" aria-label={`Arrow ${isLeft ? "left" : "right"}`}>
        {isLeft ? (
          <img src={setaEsquerda} alt="esquerda" />
        ) : (
          <img src={setaDireita} alt="direita" />
        )}
      </span>
    </button>
  );

  return (
    <div className="flex justify-center w-screen items-center">
      <div className="relative w-full">
        <div className="carousel -mt-10">
          {sliderControl(true)}
          {info.map((x, i) => (
            <div className="w-full flex-shrink-0" key={x} ref={refs[i]}>
              <div className="w-screen object-contain flex justify-center pt-10 md:pt-0">
                {x}
              </div>
            </div>
          ))}
          {sliderControl()}
        </div>
      </div>
    </div>
  );
}
