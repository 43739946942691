import React from "react";

import LogoReceita from "../logoReceita";

export default function BannerReceitas(props) {
  return (
    <div className=" flex justify-center items-center">
      <div className="flex-auto bannerListaReceitas w-screen md:mt-20 xl:mt-20 2xl:mt-32">
        <div className="mb-16">
          <LogoReceita />
        </div>
      </div>
    </div>
  );
}
