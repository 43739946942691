import React from "react";

export default function TextArea({
  name,
  label,
  value,
  disabled,
  onChange,
  error = false,
}) {
  return (
    <div className="mb-4">
      <label
        htmlFor="about"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1">
        <textarea
          name={name}
          rows={3}
          disabled={disabled}
          className={`shadow-sm focus:ring-gray-100 focus:border-gray-100 mt-1 block w-full border rounded-md py-2 px-3 text-gray-900 ${
            error
              ? "bg-red-100 border border-red-700 text-red-700"
              : "bg-white border-gray-300"
          }`}
          defaultValue={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
