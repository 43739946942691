import { React, useState, useEffect } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Fade } from "react-reveal";

import IconeHome from "../iconeHome";
import Carousel from "../carousel";

import anzol from "../../assets/icons/icon-anzol.png";
import barco from "../../assets/icons/icon-barco.png";
import caixa from "../../assets/icons/icon-caixa.png";
import caminhao from "../../assets/icons/icon-caminhao.png";
import verde from "../../assets/icons/icon-selo-verde.png";
import sif from "../../assets/icons/icon-sif.png";
import embalagens from "../../assets/static/embalagens.png";

export default function Icones() {
  const [isMobile, setMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth >= 992) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  }, [window.innerWidth]);

  const icones = [
    <IconeHome
      className=""
      image={sif}
      text="Possuímos o Selo SIF 2163. Sinônimo de qualidade e segurança"
    />,
    <IconeHome
      className=""
      image={barco}
      text="Desde 1997 levando Saúde na Mesa de muitas famílias"
    />,
    <IconeHome
      className=""
      image={caixa}
      text="Presente em grandes redes de supermercados e cozinhas industriais"
    />,
    <IconeHome
      className=""
      image={caminhao}
      text="Colaboradores que buscam entregar à você, o melhor produto!"
    />,
    <IconeHome
      className=""
      image={verde}
      text="Responsabilidade Social. Uma empresa com causas e compromissos"
    />,
    <IconeHome
      className=""
      image={anzol}
      text="Garantia de procedimento dos pescados e frutos do mar"
    />,
  ];

  return (
    <div className="icones w-screen flex justify-center items-center">
      {isMobile ? (
        <div className="flex flex-col items-center">
          <Carousel info={icones} type="div" />
          <img
            src={embalagens}
            alt="embalagens"
            className="w-12/12 mt-10"
          ></img>
        </div>
      ) : (
        <>
          <Fade left>
            <div className="h-5/6 flex flex-col items-center justify-around">
              <IconeHome
                className=""
                image={sif}
                text="Possuímos o Selo SIF 2163. Sinônimo de qualidade e segurança"
              />
              <IconeHome
                className=""
                image={barco}
                text="Desde 1997 levando Saúde na Mesa de muitas famílias"
              />
              <IconeHome
                className=""
                image={caixa}
                text="Presente em grandes redes de supermercados e cozinhas industriais"
              />
            </div>
          </Fade>
          <Fade>
            <div className="flex justify-center w-1/2">
              <img
                src={embalagens}
                alt="embalagens"
                className="w-full 3xl:w-12/12"
              />
            </div>
          </Fade>
          <Fade right>
            <div className="h-5/6 flex flex-col items-center justify-around">
              <IconeHome
                className=""
                image={verde}
                text="Responsabilidade Social. Uma empresa com causas e compromissos"
              />
              <IconeHome
                className=""
                image={caminhao}
                text="Colaboradores que buscam entregar à você, o melhor produto!"
              />
              <IconeHome
                className=""
                image={anzol}
                text="Garantia de procedimento dos pescados e frutos do mar"
              />
            </div>
          </Fade>
        </>
      )}
    </div>
  );
}
