import React, { useEffect } from 'react'

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

// Pages
import Home_Page from './pages/home'
import Lista_Receitas_Page from './pages/lista-receitas'
import Receita_Page from './pages/receita'
import Onde_Page from './pages/ondeEncontrar'
import Produtos_Page from './pages/produtos'
import Teste_Page from './pages/teste'
import Trabalhe_Page from './pages/trabalhe'
import { LoginScreen } from './pages/admin'
import { LogoutScreen } from './pages/admin'
import { PerfilScreen } from './pages/admin'
import { ReceitaScreen } from './pages/admin'
import { BannerScreen } from './pages/admin'
import { CurriculoScreen } from './pages/admin'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const _cookies = localStorage.getItem('session@sopesca')
  console.log('_user', _cookies)
  return (
    <Route
      {...rest}
      render={(props) =>
        !_cookies ? <Redirect to="/sys/login" /> : <Component {...props} />
      }
    />
  )
}

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/lista-receitas" component={Lista_Receitas_Page} />
        <Route path="/receita" component={Receita_Page} />
        <Route path="/onde-encontrar" component={Onde_Page} />
        <Route path="/produtos" component={Produtos_Page} />
        <Route path="/teste" component={Teste_Page} />
        <Route path="/trabalhe-conosco" component={Trabalhe_Page} />
        <Route path="/sys/login" component={LoginScreen} />
        <PrivateRoute path="/sys/logout" component={LogoutScreen} />
        <PrivateRoute path="/sys/receita" component={ReceitaScreen} />
        <PrivateRoute path="/sys/banner" component={BannerScreen} />
        <PrivateRoute path="/sys/cvs" component={CurriculoScreen} />
        <Route path="/sys" component={LoginScreen} />
        <Route path="/" component={Home_Page} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
