import React from "react";
import PropTypes from "prop-types";
import Loading from "../loading";

const Loader = props => {
  const { align, cover } = props;
  return (
    <div className={`loading text-${align} cover-${cover}`}>
      <Loading />
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.string,
  cover: PropTypes.string
};

Loader.defaultProps = {
  align: "center",
  cover: "inline"
};

export default Loader;
