import React from "react";

export default function IconeHome({ image, text }) {
  return (
    <div className="flex flex-col justify-center items-center w-screen md:w-full">
      <img src={image} alt="" className="mb-3 md:w-1/2" />
      <div className="robotoRegular w-48 text-center text-base">{text}</div>
    </div>
  );
}
