import React, { useEffect, useState } from 'react'

import banner1 from '../../assets/static/banner1.png'
import bannerTeste from '../../assets/static/BackgroundTeste.png'
import banner1Mobile from '../../assets/static/banner1Mobile.png'

import Carousel from '../carousel'

import { getBanner } from '../../services/so-pesca'

export default function BannerHome(props) {
  const images = [banner1, banner1]
  const imagesMobile = [banner1Mobile, banner1Mobile]

  const [isMobile, setMobile] = useState(false)
  const [listImages, setListImages] = useState([])
  const [listaBanners, setListBanners] = useState([])
  const [listaBannersMobile, setListBannersMobile] = useState([])

  useEffect(() => {
    if (window.innerWidth >= 992) {
      setMobile(false)
      setListImages(images)
    } else {
      setMobile(true)
      setListImages(imagesMobile)
    }
  }, [window.innerWidth])

  const [banners, setBanners] = useState([])

  useEffect(() => {
    loadRegisters()
  }, [])

  const loadRegisters = async () => {
    const result = await getBanner()
    console.log('result', result)
    geraLista(result.data)
  }

  const geraLista = (data) => {
    console.log('geraLista', data)
    let result = []
    let resultMobile = []
    data.map((x) => {
      if (x.link) {
        console.log('com LINK')
        result.push(
          <a href={x.link}>
            <img
              src={`https://sopescadobrasil.com.br/api/upload/${x.imagem}`}
              alt="x.nome"
              className="w-full object-contain"
            />
          </a>,
        )
        resultMobile.push(
          <a href={x.link}>
            <img
              src={`https://sopescadobrasil.com.br/api/upload/${x.imagem_mobile}`}
              alt="x.nome"
              className="w-full object-contain"
            />
          </a>,
        )
      } else {
        console.log('sem LINK')
        result.push(
          <img
            src={`https://sopescadobrasil.com.br/api/upload/${x.imagem}`}
            // src={bannerTeste}
            alt="x.nome"
            className="w-full object-contain"
          />,
        )
        resultMobile.push(
          <img
            src={`https://sopescadobrasil.com.br/api/upload/${x.imagem_mobile}`}
            // src={bannerTeste}
            alt="x.nome"
            className="w-full object-contain"
          />,
        )
      }
    })
    console.log('result', result)
    setListBanners(result)
    setListBannersMobile(resultMobile)
  }

  return (
    <div className="w-screen banner flex justify-center flex-row pt-3/10 md:pt-40">
      {isMobile ? (
        <div style={{ marginTop: '120px' }}>
          <Carousel info={listaBannersMobile} />
        </div>
      ) : (
        <Carousel info={listaBanners} />
      )}
    </div>
  )
}
