import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";

import bandejas from "../../assets/static/bandejas.png";
import bandejasMobile from "../../assets/static/bandejasMobile.png";
import logo from "../../assets/static/logo-amazonia.png";
import selo from "../../assets/static/selo-amazonia.png";

export default function Amazonia() {
  const [isMobile, setMobile] = useState(false);
  const [listImages, setListImages] = useState([]);
  useEffect(() => {
    if (window.innerWidth >= 992) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  }, [window.innerWidth]);

  return (
    <div className="amazonia w-screen pb-14 pt-24">
      <div className="folhasAmazonia pb-24 -mt-6 md:pt-24 px-7 w-screen flex justify-center items-center">
        {isMobile ? (
          <Fade bottom>
            <div>
              <div className="w-1/2">
                <img src={logo} alt="logo amazonia" className="w-full" />
              </div>
              <div className=" w-full flex -mt-44 ml-7">
                <img
                  src={bandejasMobile}
                  alt="bandeja filé de peixe"
                  className="w-full"
                />
              </div>
              <div className="w-full flex justify-end -mt-44">
                <img
                  src={selo}
                  alt="selo cultivo sustentavel"
                  className="w-2/5"
                />
              </div>
              <div className=" p-4 mt-5 w-full flight text-mainblue text-3xl font-bold">
                Uma nova linha com o sabor do Brasil, vai fazer parte da mesa do
                seu cliente.
              </div>
            </div>
          </Fade>
        ) : (
          <>
            <Fade bottom>
              <div className="md:ml-44 w-full md:w-2/5 flex">
                <img
                  src={bandejas}
                  alt="bandeja filé de peixe"
                  className="w-full"
                />
              </div>
              <div className="w-full md:w-2/3 -ml-44">
                <div className="flex">
                  <div className="w-2/3 -ml-16 md:ml-0 md:w-1/3">
                    <img src={logo} alt="logo amazonia" className="w-full" />
                  </div>
                  <div className="md:ml-6 md:mt-10 w-3/5 md:w-1/5 mt-44">
                    <img
                      src={selo}
                      alt="selo cultivo sustentavel"
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="md:ml-28 mr-0 mt-5 w-full md:w-1/2 flight text-mainblue text-4xl font-bold">
                  Uma nova linha com o sabor do Brasil, vai fazer parte da mesa
                  do seu cliente.
                </div>
              </div>
            </Fade>
          </>
        )}
      </div>
    </div>
  );
}
