import React from "react";
import { configureAnchors } from "react-scrollable-anchor";

import { Menu, BannerTrabalhe, ConteudoTrabalhe, FormTrabalhe, Rodape } from "../../components";

const Trabalhe_Page = () => {
  configureAnchors({ offset: -60, scrollDuration: 2000 });

  return (
    <div>
      <Menu />
      <BannerTrabalhe />
      <ConteudoTrabalhe />
      <FormTrabalhe />
      <Rodape />
    </div>
  );
};

export default Trabalhe_Page;
