import React from "react";

export default function Mapa() {
  return (
    <div className="mapa w-screen flex flex-col items-center pt-4 md:pt-8 sm:pb-4 md:pb-14">
      <div className="robotoBold text-mainblue text-xl px-7 text-center mb-10">
        Aqui temos um mapa com as lojas e produtos Só Pesca.
        <br />
        Encontre seu pescado predileto, bem pertinho de você!
      </div>
      <iframe
        src="https://munddi.com/SoPesca?e=1&logo=1"
        width="80%"
        height="500px"
        allow="geolocation *;"
      ></iframe>
      <div className="robotoRegular text-mainblue mb-10 md:mb-0 text-center mt-8 text-lg px-7">
        Dependendo da conﬁguração do seu navegador, o mapa traz automaticamente
        os pontos de <br /> venda mais próximos a você, ou de algum endereço que
        você quiser usar de referência.
        <br />
        <br /> Ahhh, para ter acesso a todas as opções perto de você, tire o
        máximo de zoom do seu mapa 
      </div>
    </div>
  );
}
