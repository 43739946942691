import React, { useEffect, useState } from 'react'
import {
  ButtonComponent,
  Checkbox,
  InputCurrency,
  InputText,
  InputTime,
  SlideComponent,
  TextArea,
  Upload,
} from '../../../components/componentsAdmin'
import Models from '../../../models'

import { CheckIcon, XIcon } from '@heroicons/react/solid'

import { postBanner, putBanner } from '../../../services/so-pesca'

export default function ItemFormComponent({
  open,
  setOpen,
  data = null,
  saveAndClose,
  showError,
}) {
  const [content, setContent] = useState(Models.modelBanner)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    //console.log('restaurant', restaurant)
    if (data) {
      //console.log('data', data)
      setContent({ ...data })
    }
    setLoading(false)
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const reister = {
      ...content,
    }
    const { status, ...rest } = reister

    let result = null
    if (data) {
      result = await putBanner(rest)
    } else {
      result = await postBanner(rest)
    }

    if (result === false) {
      showError()
      setLoading(false)
      return false
    }
    saveAndClose()
  }

  const handleChange = (e) => {
    setContent({ ...content, [e.target.name]: e.target.value })
  }

  const handleCheck = (e) => {
    setContent({ ...content, [e.target.name]: e.target.checked })
  }

  return (
    <SlideComponent
      open={open}
      setOpen={setOpen}
      title={`${data ? 'Editar' : 'Cadastrar'} item`}
    >
      <form
        className="flex flex-col justify-center p-8"
        onSubmit={handleSubmit}
      >
        <InputText
          name="nome"
          label="Nome"
          onChange={handleChange}
          value={content.nome}
          required={true}
          type="text"
        />

        <Upload
          name="imagem"
          label="Imagem do banner"
          value={content.imagem}
          onUpload={(value) => setContent({ ...content, imagem: value })}
          showError={showError}
        />

        <Upload
          name="imagem_mobile"
          label="Imagem Mobile do banner"
          value={content.imagem_mobile}
          onUpload={(value) => setContent({ ...content, imagem_mobile: value })}
          showError={showError}
        />

        <InputText
          name="link"
          label="Link"
          onChange={handleChange}
          value={content.link}
          required={true}
          type="text"
        />

        <div className="px-4 py-4 bg-gray-50 flex justify-between">
          <ButtonComponent
            label="Cancelar"
            icon={<XIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />}
            action={setOpen}
            bg="transparent"
            color="black"
            border="gray-300"
            classname="mr-8"
            disabled={loading}
          />

          <ButtonComponent
            type="submit"
            label="Salvar"
            className="bg-blue-500"
            icon={
              <CheckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            }
            action={handleSubmit}
            bg="blue"
            disabled={loading}
          />
        </div>
      </form>
    </SlideComponent>
  )
}
