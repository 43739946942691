import React, { useEffect, useRef, useState } from "react";
import { Fade } from "react-reveal";
import InputMask from "react-input-mask";

import { postCv } from "../../services/so-pesca/digital-items-service";
import { postUploadImage } from "services/so-pesca/digital-items-service";

const limitMin = 50;
const data = {
  name: "",
  email: "",
  phone: "",
  age: "",
  city: "",
  arquivo: null,
};

export default function Trabalhe() {
  const [required] = useState(false);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(false);
  const [errName, setErrName] = useState(false);
  const [errEmail, setErrEmail] = useState(false);
  const [errPhone, setErrPhone] = useState(false);
  const [errAge, setErrAge] = useState(false);
  const [errCity, setErrCity] = useState(false);
  const [errArquivo, setErrArquivo] = useState(false);
  const [errCheckbox, setErrCheckbox] = useState(false);
  const [countMin, setCountMin] = useState(limitMin);
  const [content, setContent] = useState(data);
  const [file, setFile] = useState(null);
  const [checkbox, setCheckbox] = useState(false);

  const refName = useRef(null);

  const validateInput = () => {
    let err = false;

    if (content.name.length < 2) {
      setErrName(true);
      err = true;
    }
    if (content.phone.length < 15) {
      setErrPhone(true);
      err = true;
    }
    if (content.age.length < 1 || content.age.length > 3) {
      setErrAge(true);
      err = true;
    }
    if (content.city.length < 2) {
      setErrCity(true);
      err = true;
    }

    if (content.arquivo === null) {
      setErrArquivo(true);
      err = true;
    }

    if (checkbox === false) {
      setErrCheckbox(true);
      err = true;
    }

    if (err) {
      setLoading(false);
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateInput()) return false;

    console.log("handleSubmit", content);
    const result = await postCv(content);
    console.log("handleSubmit", result);

    if (!result.error) {
      setComplete(true);
      setError(false);
      setContent(data);
    } else {
      setComplete(false);
      setError(true);
    }

    setLoading(false);
    return false;
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const field = e.target.id;
    const min = e.target.minLength;
    if (value.length > min) {
      if (field === "name") setErrName(false);
      if (field === "phone") setErrPhone(false);
      if (field === "age") setErrAge(false);
      if (field === "city") setErrCity(false);
      if (field === "arquivo") setErrArquivo(false);
    }

    setContent({ ...content, [field]: value });
  };
  const reset = () => {
    setContent(data);
    setError(false);
    setErrName(false);
    setErrEmail(false);
    setErrPhone(false);
    setErrAge(false);
    setErrCity(false);
    refName.current.focus();
  };

  const handleUpload = async (e) => {
    console.log("handleUpload file: ", e.target.files[0]);

    setLoading(true);

    const data = new FormData();
    data.append("file", e.target.files[0], e.target.files[0].name);

    const result = await postUploadImage(data);
    if (result === false) {
      setErrArquivo(true);
      setLoading(false);
      return false;
    }
    console.log("result", result);

    content.arquivo = result.arquivo;
    setLoading(false);
  };

  const handleRemove = () => {
    content.arquivo = null;
  };

  return (
    <div className="trabalhe w-screen flex flex-col items-center pb-8 md:pb-14">
      <div className="robotoRegular text-mainblue text-xl px-7 md:px-32 text-center mb-10">
        Faça parte do time de uma empresa com mais de 24 anos como referência
        <br /> em levar a Saúde da Natureza para a mesa de diversas famílias:
      </div>

      <Fade up duration={2000}>
        <div className="flex w-full md:w-2/5 content-center justify-center">
          <div className="w-full 2xl:w-11/12 formulario p-4 lg:p-8">
            <form
              className="flex flex-col justify-center"
              onSubmit={handleSubmit}
            >
              <input
                ref={refName}
                id="name"
                disabled={loading}
                value={content.name}
                className={`shadow-inner rounded-md mb-4 p-2 w-full family-medium font-dark ${
                  errName
                    ? "bg-red-100 border border-red-700 text-red-700"
                    : "bg-gray-200"
                }`}
                required={required}
                type="text"
                placeholder="Nome Completo"
                onChange={handleChange}
                minLength={2}
                tabIndex={1}
              />
              <InputMask
                mask="(99) 99999-9999"
                maskChar={null}
                value={content.phone}
                className={`shadow-inner rounded-md mb-4 p-2 w-full family-medium font-dark ${
                  errPhone
                    ? "bg-red-100 border border-red-700 text-red-700"
                    : "bg-gray-200"
                }`}
                required={required}
                id="phone"
                placeholder="Telefone"
                onChange={handleChange}
                tabIndex={2}
              />
              <div className="flex justify-between flex-col md:flex-row">
                <input
                  disabled={loading}
                  value={content.email}
                  aria-errormessage={true}
                  className={`shadow-inner rounded-md mb-4 p-2 w-full md:w-3/5 family-medium font-dark ${
                    errName
                      ? "bg-red-100 border border-red-700 text-red-700"
                      : "bg-gray-200"
                  }`}
                  required={required}
                  id="email"
                  type="email"
                  onChange={(e) =>
                    setContent({ ...content, email: e.target.value })
                  }
                  aria-label="email address"
                  placeholder="E-mail"
                  tabIndex={3}
                />
                <input
                  id="age"
                  disabled={loading}
                  value={content.age}
                  className={`shadow-inner rounded-md mb-4 p-2 w-full md:w-4/12 family-medium font-dark ${
                    errAge
                      ? "bg-red-100 border border-red-700 text-red-700"
                      : "bg-gray-200"
                  }`}
                  required={required}
                  type="text"
                  placeholder="Idade:"
                  onChange={handleChange}
                  minLength={2}
                  tabIndex={4}
                />
              </div>
              <div className="flex justify-between w-full flex-col md:flex-row">
                <input
                  id="city"
                  disabled={loading}
                  value={content.city}
                  className={`shadow-inner rounded-md mb-4 p-2 w-full md:w-5/12 family-medium font-dark ${
                    errCity
                      ? "bg-red-100 border border-red-700 text-red-700"
                      : "bg-gray-200"
                  }`}
                  required={required}
                  type="text"
                  placeholder="Cidade:"
                  onChange={handleChange}
                  minLength={2}
                  tabIndex={5}
                />
                <input
                  id="file"
                  disabled={loading}
                  value={file}
                  className={`shadow-inner rounded-md mb-4 p-2 w-full md:w-6/12 family-medium font-dark ${
                    errArquivo
                      ? "bg-red-100 border border-red-700 text-red-700"
                      : "bg-gray-200"
                  }`}
                  required={required}
                  type="file"
                  placeholder="Anexe aqui seu curriculo:"
                  onChange={handleUpload}
                  tabIndex={6}
                />
              </div>
              <div className="flex mt-5">
                <input
                  id="checkbox"
                  disabled={loading}
                  checked={checkbox}
                  onChange={() => {
                    setCheckbox(!checkbox);
                  }}
                  className={`rounded-md mb-4 p-5 w-full md:w-6/12 family-medium font-dark ${
                    errCheckbox
                      ? "bg-red-100 border border-red-700 text-red-700"
                      : "bg-gray-200"
                  }`}
                  required={required}
                  type="checkbox"
                  placeholder="Anexe aqui seu curriculo:"
                  tabIndex={7}
                />
                <div className="text-black robotoRegular">
                  Ao enviar o meu currículo na para a SÓ PESCA BRASIL, manifesto
                  o meu consentimento livre, informado e inequívoco para que
                  meus dados pessoais sejam tratados pela empresa com a
                  finalidade de análise e, se for o caso, seleção em eventual
                  processo de contratação para compor seu quadro de empregados,
                  bem como o compartilhamento dos dados pessoais constantes no
                  currículo com empresas do mesmo grupo econômico da SÓ PESCA.
                  <br />
                  <br />
                  Autorizo ainda a empresa a armazenar o meu currículo em seu
                  banco de dados pelo prazo de 01 (um ano). Após este período,
                  todos os dados serão automaticamente eliminados de forma
                  segura pela empresa, ressalvadas as hipóteses em que, por
                  obrigação legal ou regulatória, seja obrigada a mantê-los.
                </div>
              </div>

              <span className="text-white mb-6 italic text-sm">
                *Mínimo de {limitMin} caracteres... restam:{" "}
                <strong>{countMin}</strong>
              </span>

              <div className="flex justify-center w-full">
                <button
                  tabIndex={5}
                  type="submit"
                  disabled={loading}
                  className="transition rounded-full duration-500 ease-in-out bg-mainorange transform py-2 family-bold uppercase text-lg w-24"
                >
                  Enviar
                </button>
              </div>

              {(errName || errPhone || errAge || errCity || errArquivo) && (
                <Fade top>
                  <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md w-4/5 mt-4 justify-between"
                    role="alert"
                  >
                    <strong className="font-bold">Alerta!</strong>
                    <span className="block sm:inline ml-3">
                      Preencha todos os campos corretamente.
                    </span>
                  </div>
                </Fade>
              )}

              {complete && (
                <Fade top>
                  <div
                    className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-md w-4/5 mt-4 justify-between"
                    role="alert"
                  >
                    <strong className="font-bold text-mainblue">
                      Sucesso!
                    </strong>
                    <span className="block sm:inline ml-3  text-black">
                      Contato enviado com sucesso!
                    </span>
                  </div>
                </Fade>
              )}

              {error && (
                <Fade top>
                  <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md w-4/5 mt-4 justify-between"
                    role="alert"
                  >
                    <strong className="font-bold">ERRO!</strong>
                    <span className="block sm:inline ml-3">
                      Houve uma falha no envio, tente novamente.
                    </span>
                  </div>
                </Fade>
              )}

              {loading && (
                <Fade left>
                  <div className="mt-16 text-white">
                    Aguarde, enviando seu contato...
                  </div>
                </Fade>
              )}
            </form>
          </div>
        </div>
      </Fade>
    </div>
  );
}
