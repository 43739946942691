import React, {useEffect} from 'react';
import Content from './style';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from 'store/actions';
import * as Icon from 'react-bootstrap-icons'

export default function FlashMessageCookies() {
		
		const flash_message = useSelector(({modal}) => modal.cookies);
		const dispatch = useDispatch()
		
		useEffect(() => {
				// localStorage.clear();
				loadCookies();
		}, [])
		
		const loadCookies = () => {
				const lgpd_cookies = localStorage.getItem('lgpd_cookies');
				if (lgpd_cookies && lgpd_cookies === 'true') {
						dispatch(Actions.close_modal('cookies'));
				}
		}
		
		const closeFlashMessage = (e) => {
				dispatch(Actions.close_modal('cookies'));
				localStorage.setItem('lgpd_cookies', 'true');
		}
		
		return flash_message && <Content className='flex flex-auto flex-col md:w-90 w-full text-center'>
			<div className='flex flex-auto md:flex-row flex-col mx-20'>
				<div className='flex flex-auto flex-row'>
					<div className='flex flex-auto'>
						<Icon.InfoCircle color='#FFFFFF' size={25} className='mr-3 d-none d-md-block'/>
					</div>
					<div>
						A <strong>Só Pesca</strong> utiliza cookies e guarda estatísticas de visitas para melhorar sua
						experiência de navegação, de acordo com a nossa <a href="#politica-privacidade" onClick={() => dispatch(Actions.toggle_modal('politica'))}>Política de Privacidade</a>,
						e ao continuar
						navegando, você concorda com estas condições.
					</div>
				</div>
				
				<div className='flex flex-auto flex-col justify-center md:mt-0 mt-4'>
					<button onClick={closeFlashMessage} className='ml-3 h-100'>Continuar</button>
				</div>
			</div>
			
		</Content>
}
