import React, { useEffect, useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css'

import CardReceita from '../cardReceita'

import Carousel from '../carousel'

import cuscuz from '../../assets/static/cuscuz.png'
import macarrao from '../../assets/static/macarrao.png'
import peixe from '../../assets/static/peixe.png'
import coxinha from '../../assets/static/coxinha.png'
import sardinha from '../../assets/static/sardinha.png'
import tilapia from '../../assets/static/tilapia.png'

import { getReceita } from '../../services/so-pesca'

export default function VejaMais(props) {
  const [isMobile, setMobile] = useState(false)
  const [listaMobile, setListaMobile] = useState([]);

  useEffect(() => {
    if (window.innerWidth >= 640) {
      setMobile(false)
    } else {
      setMobile(true)
    }
  }, [window.innerWidth])

  const [receitas, setReceitas] = useState([])

  useEffect(() => {
    loadRegisters()
  }, [])

  const loadRegisters = async () => {

    const result = await getReceita()
    
    setReceitas(result.data.reverse())
    geraLista(result.data);
  }

  const geraLista = (data) => {
    let resultMobile = [];
    data.map((x) => {
      resultMobile.push(<CardReceita receita={x} img={macarrao} />)
    })
    console.log('resultMobile :: ', resultMobile);
    setListaMobile(resultMobile);
  }

  return (
    <div className="vejaMais flex flex-col items-center pb-4 md:pb-32">
      <div className="text-mainblue sensations text-7xl mb-8">Veja Também</div>
      {isMobile && listaMobile.length > 0 ?
        <div className="w-screen flex justify-center">
          <Carousel info={listaMobile} type="div" />
        </div>
      : (
        <div className="flex justify-around w-screen m-5 min-h-36">
          <Splide
            className="w-screen"
            options={{
              rewind: true,
              perPage: 3,
              width: '80vw',
              gap: '0rem',
              padding: '0rem',
              pagination: true,
            }}
          >
            {receitas?.map((x) => {
              return (
                <SplideSlide className="flex justify-center min-h-36">
                  <CardReceita receita={x} img={macarrao} />
                </SplideSlide>
              )
            })}
          </Splide>
        </div>
      )}
    </div>
  )
}
