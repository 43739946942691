import React from "react";
import Tooltip from "@mui/material/Tooltip";

export default function InputText({
  name,
  label,
  value,
  disabled,
  onChange,
  type,
  classname,
  required = false,
  image = false,
  placeholder,
}) {
  return (
    <div
      className={`mb-4 ${classname} ${
        image && "row flex justify-between items-center align-middle"
      }`}
    >
      <div className={`${image ? "w-10/12" : "w-full"}`}>
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <input
          type={type}
          name={name}
          className={`shadow-sm focus:ring-gray-500 focus:gray mt-1 block w-full border rounded-md bg-white border-gray-300 py-2 px-3  text-gray-900`}
          value={value}
          disabled={disabled}
          onChange={onChange}
          required={required}
          placeholder={placeholder}
        />
      </div>

      {image && (
        <Tooltip
          className="p-0"
          title={<img src={value} alt="" width="250" />}
          placement="left"
        >
          <div className="w-2/12 flex justify-center mt-2">
            <img
              className="inline-block h-16 w-16 rounded-lg ring-2 ring-white"
              src={value}
              alt=""
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
}
