import React from "react";
import { configureAnchors } from "react-scrollable-anchor";

import {
  Menu,
  BannerHome,
  Sobre,
  Rodape,
  Icones,
  Amazonia,
  Pratica,
  ReceitasHome,
  ModalPolitica,
  FlashMessageCookies
} from "../../components";

const Home_Page = () => {
  configureAnchors({ offset: -60, scrollDuration: 2000 });

  return (
    <div>
      <ModalPolitica />
      <FlashMessageCookies/>
      <Menu />
      <BannerHome />
      <Sobre />
      <Icones />
      <Amazonia />
      <Pratica />
      <ReceitasHome />
      <Rodape />
    </div>
  );
};

export default Home_Page;
