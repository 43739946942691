import React from "react";

import instagram from "../../assets/icons/instagran_laranja.png";
import facebook from "../../assets/icons/face_laranja.png";
import youtube from "../../assets/icons/youtube_laranja.png";
import whats from "../../assets/icons/whats_laranja.png";

export default function Header(props) {
  return (
    <div className=" w-screen h-14 bg-mainblue flex justify-end">
      <div className="w-2/3 md:w-1/5 flex justify-around items-center md:mr-32">
        <a href="/sys/login" className="text-sm md:text-normal">
          Área Restrita
        </a>
        <a
          href="https://www.instagram.com/so.pesca/"
          target="blank"
          className="w-8 mx-1.5 ml-4"
        >
          <img src={instagram} alt="instagram" className="w-8"></img>
        </a>
        <a
          href="https://www.facebook.com/sopescabrasil"
          target="blank"
          className="w-8 mx-1.5"
        >
          <img src={facebook} alt="Facebook" className="w-8"></img>
        </a>
        <a
          href="https://www.youtube.com/channel/UCHcqWhRgoeM8SoPSdlyv9jw"
          target="blank"
          className="w-8 mx-1.5"
        >
          <img src={youtube} alt="youtube" className="w-8"></img>
        </a>
        <a
          href="https://wa.me/c/5515996620757"
          target="blank"
          className="w-8 mx-1.5"
        >
          <img src={whats} alt="icone whatsapp" className="w-8"></img>
        </a>
      </div>
    </div>
  );
}
