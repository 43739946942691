import Video from "../../components/video";

export default function ConteudoReceita(props) {
  console.log("receita", props.receita);
  return (
    <>
      {props.receita ? (
        <div className="w-screen flex flex-col items-center pt-4 md:pt-8 pb-8 md:pb-14 px-8 md:px-52 ">
          <div className="robotoBold text-black w-full text-lg">
            Ingredientes:
          </div>
          <div className="robotoRegular text-black w-full text-lg">
            <div
              style={{ whiteSpace: 'break-spaces' }}
              dangerouslySetInnerHTML={{
                __html: props.receita.ingredientes,
              }}
            />
          </div>
          <br />
          <br />
          <div className="robotoBold text-black w-full text-lg">
            Modo de Preparo:
          </div>
          <div className="robotoRegular text-black w-full text-lg flex-wrap">
            <div
              style={{ whiteSpace: 'break-spaces' }}
              dangerouslySetInnerHTML={{
                __html: props.receita.preparo,
              }}
            />
          </div>
          {props.receita.video !== null ? props.receita.video.length > 0 &&
          <div className="w-full">
            <div className="text-mainblue sensations text-7xl mb-8 mt-8 text-center">Receita Passo a Passo</div>
            <Video embedId={props.receita.video} />
          </div> : <></>}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
