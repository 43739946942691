import { useEffect, useState } from "react";
import { configureAnchors } from "react-scrollable-anchor";

import { getReceitaId } from "../../services/so-pesca";

import {
  BannerReceita,
  ConteudoReceita,
  // Video,
  Menu,
  Rodape,
  VejaMais
} from "../../components";

const Receitas_Page = props => {
  configureAnchors({ offset: -60, scrollDuration: 2000 });

  const [loading, setLoading] = useState(true);
  const [receita, setReceita] = useState([]);

  useEffect(() => {
    loadRegisters();
  }, []);

  const loadRegisters = async () => {
    setLoading(true);

    let str = props.location.search.replace("?receita=", "").split("&");
    //let id = props.match.params.id;
    let id = str[0];
    console.log("🚀 ~ file: index.js:19 ~ id:", id);
    // id = id.split("_")[0];
    // console.log(id);

    const result = await getReceitaId(id);
    setReceita(result.data);
    setLoading(false);
  };

  console.log("receita", receita);

  return (
    <div>
      <Menu />
      {!loading && <BannerReceita receita={receita} />}
      {!loading && <ConteudoReceita receita={receita} />}
      {/* {!loading && <Video receita={receita} />} */}
      <VejaMais />
      <Rodape />
    </div>
  );
};

export default Receitas_Page;
