import React, { useEffect, useState } from "react";
import {
  ButtonComponent,
  Checkbox,
  InputCurrency,
  InputText,
  InputTime,
  SlideComponent,
  TextArea,
  Upload,
} from "../../../components/componentsAdmin";
import Models from "../../../models";

import { CheckIcon, XIcon } from "@heroicons/react/solid";

import {
  postCv,
  putCv,
} from "../../../services/so-pesca/digital-items-service";
// import { toAmountString } from "../../../formatters/currency-formatter";

export default function ItemFormComponent({
  open,
  setOpen,
  data = null,
  saveAndClose,
  showError,
}) {
  const [content, setContent] = useState(Models.modelCv);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //console.log('restaurant', restaurant)
    if (data) {
      //console.log('data', data)
      setContent({ ...data });
    }
    setLoading(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const reister = {
      ...content,
    };
    const { status, ...rest } = reister;

    let result = null;
    if (data) {
      result = await putCv(rest);
    } else {
      console.log(rest);
      result = await postCv(rest);
    }

    if (result === false) {
      showError();
      setLoading(false);
      return false;
    }
    saveAndClose();
  };

  const handleChange = (e) => {
    setContent({ ...content, [e.target.name]: e.target.value });
  };

  const handleCheck = (e) => {
    setContent({ ...content, [e.target.name]: e.target.checked });
  };

  return (
    <SlideComponent
      open={open}
      setOpen={setOpen}
      title={`${data ? "Editar" : "Cadastrar"} item`}
    >
      <form
        className="flex flex-col justify-center p-8"
        onSubmit={handleSubmit}
      >
        <InputText
          name="nome"
          label="Nome"
          onChange={handleChange}
          value={content.name}
          required={true}
          type="text"
        />
        <InputText
          name="email"
          label="Email"
          onChange={handleChange}
          value={content.email}
          required={true}
          type="text"
        />
        <InputText
          name="phone"
          label="Telefone"
          onChange={handleChange}
          value={content.phone}
          required={true}
          type="text"
        />
        <InputText
          name="age"
          label="Idade"
          onChange={handleChange}
          value={content.age}
          required={true}
          type="text"
        />
        <InputText
          name="city"
          label="Cidade"
          onChange={handleChange}
          value={content.city}
          required={true}
          type="text"
        />
        <Upload
          name="arquivo"
          label="Curriculo"
          value={content.arquivo}
          onUpload={(value) => setContent({ ...content, arquivo: value })}
          showError={showError}
        />

        <div className="px-4 py-4 bg-gray-50 flex justify-between">
          <ButtonComponent
            label="Cancelar"
            icon={<XIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />}
            action={setOpen}
            bg="transparent"
            color="black"
            border="gray-300"
            classname="mr-8"
            disabled={loading}
          />

          <ButtonComponent
            type="submit"
            label="Salvar"
            icon={
              <CheckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            }
            action={handleSubmit}
            bg="blue"
            disabled={loading}
          />
        </div>
      </form>
    </SlideComponent>
  );
}
