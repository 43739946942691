import React, { useEffect, useState } from "react";
import {
  ButtonComponent,
  Checkbox,
  InputCurrency,
  InputText,
  InputTime,
  SlideComponent,
  TextArea,
  Upload,
} from "../../../components/componentsAdmin";
import Models from "../../../models";

import { CheckIcon, XIcon } from "@heroicons/react/solid";

import {
  postReceita,
  putReceita,
} from "../../../services/so-pesca/digital-items-service";
// import { toAmountString } from "../../../formatters/currency-formatter";

export default function ItemFormComponent({
  open,
  setOpen,
  data = null,
  saveAndClose,
  showError,
}) {
  const [content, setContent] = useState(Models.modelReceita);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //console.log('restaurant', restaurant)
    if (data) {
      //console.log('data', data)
      setContent({ ...data });
    }
    setLoading(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const reister = {
      ...content,
    };
    const { status, ...rest } = reister;

    let result = null;
    if (data) {
      result = await putReceita(rest);
    } else {
      console.log(rest);
      result = await postReceita(rest);
    }

    if (result === false) {
      showError();
      setLoading(false);
      return false;
    }
    saveAndClose();
  };

  const handleChange = (e) => {
    setContent({ ...content, [e.target.name]: e.target.value });
  };

  const handleCheck = (e) => {
    setContent({ ...content, [e.target.name]: e.target.checked });
  };

  return (
    <SlideComponent
      open={open}
      setOpen={setOpen}
      title={`${data ? "Editar" : "Cadastrar"} item`}
    >
      <form
        className="flex flex-col justify-center p-8"
        onSubmit={handleSubmit}
      >
        <InputText
          name="nome"
          label="Nome"
          onChange={handleChange}
          value={content.nome}
          required={true}
          type="text"
        />

        <Upload
          name="imagemCard"
          label="Imagem Card"
          value={content.imagemCard}
          onUpload={(value) => setContent({ ...content, imagemCard: value })}
          showError={showError}
        />
        <Upload
          name="imagemBanner"
          label="Imagem Banner"
          value={content.imagemBanner}
          onUpload={(value) => setContent({ ...content, imagemBanner: value })}
          showError={showError}
        />

        <TextArea
          name="ingredientes"
          label="Ingredientes"
          onChange={handleChange}
          value={content.ingredientes}
        />
        <TextArea
          name="preparo"
          label="Modo de Preparo"
          onChange={handleChange}
          value={content.preparo}
        />
        <InputText
          name="video"
          label="Youtube"
          onChange={handleChange}
          value={content.video}
          required={true}
          type="text"
        />
        <div className="px-4 py-4 bg-gray-50 flex justify-between">
          <ButtonComponent
            label="Cancelar"
            icon={<XIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />}
            action={setOpen}
            bg="transparent"
            color="black"
            border="gray-300"
            classname="mr-8"
            disabled={loading}
          />

          <ButtonComponent
            type="submit"
            label="Salvar"
            icon={
              <CheckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            }
            action={handleSubmit}
            bg="blue"
            disabled={loading}
          />
        </div>
      </form>
    </SlideComponent>
  );
}
