const paths = {
  item: {
    index: "/banner",
    create: "/banner/novo",
    edit: (item) => `/banner/${item}/editar`,
  },
  section: {
    index: "/receita",
    create: "/receita/novo",
    edit: (item) => `/receita/${item}/editar`,
  },
  menu: {
    index: "/cv",
  },
  restaurant: {
    index: "/restaurantes",
    create: "/restaurantes/novo",
    edit: (item) => `/restaurantes/${item}/editar`,
  },
  profile: {
    password: "/perfil",
  },
  slots: {
    index: "/slots",
  },
  oauth: {
    login: "/login",
    logout: "/logout",
  },
};

export default paths;
