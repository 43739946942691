import * as modelItem from "./itemModel";
import * as modelSection from "./sectionsModel";
import * as modelMenu from "./menuModel";
import * as modelRestaurant from "./restaurantModel";
import * as modelBanner from "./bannerModel";
import * as modelReceita from "./receitaModel";
import * as modelCv from "./cvModel";

const Models = {
  ...modelItem,
  ...modelSection,
  ...modelMenu,
  ...modelRestaurant,
  ...modelBanner,
  ...modelReceita,
  ...modelCv,
};

export default Models;
