import React, { useEffect, useState } from "react";
import { toCurrencyString } from "../../../formatters/currency-formatter";
import { toDateString } from "../../../formatters/date-formatter";
import { ButtonComponent, InputText } from "../index";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/solid";

import Tooltip from "@mui/material/Tooltip";

export default function TableComponent({
  table = [],
  data = [],
  handleEdit = null,
  handleDelete = null,
  showAction = false,
  showDelete = true,
  showAttach = false,
  compare = [],
  changeHighlight = null,
  changeStatus = null,
}) {
  const [filtered, setFiltered] = useState(data);
  const [filter_field, setField] = useState("id");
  const [filter_order, setOrder] = useState("desc");
  const [filter_find, setFind] = useState("");

  useEffect(() => {
    console.log("data", data);
    handleOrder();
  }, [data]);

  const formatData = (value, type, id) => {
    if (type === "image") {
      return (
        <Tooltip
          className="p-0"
          title={<img src={`https://sopescadobrasil.com.br/api/upload/${value}`} alt="" width="250" />}
          placement="right"
        >
          <img
            data-tip
            data-for={`img-${id}`}
            className="inline-block h-8 w-8 rounded-lg ring-2 ring-white"
            src={`https://sopescadobrasil.com.br/api/upload/${value}`}
            alt=""
          />
        </Tooltip>
      );
    }

    if (type === "file") {
      // let file = get
      return (
        // <Tooltip
        //   className="p-0"
        //   title={<img src={`/api/upload/${value}`} alt="" width="250" />}
        //   placement="right"
        // >
        //   <img
        //     data-tip
        //     data-for={`img-${id}`}
        //     className="inline-block h-8 w-8 rounded-lg ring-2 ring-white"
        //     src={`/api/upload/${value}`}
        //     alt=""
        //   />
        // </Tooltip>
        <a
          className=""
          href={`https://sopescadobrasil.com.br/api/upload/${value}`}
          target="_blank"
          rel="noreferrer"
        >
          Baixar
        </a>
      );
    }
  };

  const handleOrder = (_data = data, field = filter_field) => {
    let order = "asc";

    if (filter_field === field) {
      if (filter_order === "asc") {
        setOrder("desc");
        order = "desc";
      } else if (filter_order === "desc") {
        setOrder("asc");
        order = "asc";
      }
    } else {
      setField(field);
      setOrder("asc");
      order = "asc";
    }

    const result = _data.sort((a, b) => {
      if (order === "asc") {
        if (a[field] > b[field]) return 1;
      }

      if (order === "desc") {
        if (a[field] < b[field]) return 1;
      }
    });

    setFiltered(result);
  };

  const handleSearch = (find = filter_find) => {
    setFind(find);
    const apply_filters = data.filter(
      (x) => x.name.toUpperCase().indexOf(find.toUpperCase()) > -1
    );

    if (apply_filters.length > 0) {
      handleOrder(apply_filters);
    } else {
      setFiltered([]);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-end">
        <InputText
          classname="w-4/12"
          onChange={(e) => handleSearch(e.target.value)}
          name="title"
          placeholder="Buscar por NOME"
        />
      </div>

      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {table?.map((col, index) => {
                    return (
                      <th
                        key={index}
                        scope="col"
                        className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                          col.order ? "cursor-pointer" : ""
                        }`}
                        onClick={() =>
                          col.order ? handleOrder(filtered, col.data) : null
                        }
                      >
                        <div className="flex justify-between items-center">
                          {col.label}
                          {col.order && (
                            <div className="flex-col">
                              <ChevronUpIcon
                                className={`h-3 w-3 ${
                                  col.data === filter_field &&
                                  filter_order === "desc"
                                    ? "text-black"
                                    : "text-gray-300"
                                }`}
                                aria-hidden="true"
                              />
                              <ChevronDownIcon
                                className={`h-3 w-3 ${
                                  col.data === filter_field &&
                                  filter_order === "asc"
                                    ? "text-black"
                                    : "text-gray-300"
                                }`}
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  })}
                  {showAction && (
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Ações</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filtered.length > 0 ? (
                  filtered.map((item, index) => {
                    return (
                      <tr key={`tr-${index}`} className="hover:bg-yellow-100">
                        {table?.map((col, index) => {
                          return (
                            <td
                              key={`td-${index}`}
                              className={`px-6 py-3 text-black font-bold ${
                                col.data === "price" && "whitespace-nowrap"
                              }`}
                            >
                              {col.format
                                ? formatData(
                                    item[col.data],
                                    col.format,
                                    item.id
                                  )
                                : item[col.data]}
                            </td>
                          );
                        })}

                        {/*// normal action */}
                        {showAction && (
                          <td
                            key={`td-99`}
                            className="whitespace-nowrap"
                            width="130px"
                          >
                            <div className="text-center">
                              <ButtonComponent
                                icon={
                                  <PencilIcon
                                    className="h-3 w-3"
                                    aria-hidden="true"
                                  />
                                }
                                action={() => handleEdit(item)}
                                bg="blue"
                                classname="px-1 py-1"
                              />
                              {showDelete && (
                                <ButtonComponent
                                  icon={
                                    <XIcon
                                      className="h-3 w-3"
                                      aria-hidden="true"
                                    />
                                  }
                                  action={() => handleDelete(item)}
                                  bg="white"
                                  color="black"
                                  border="black-500"
                                  classname="ml-4 px-1 py-1"
                                />
                              )}
                            </div>
                          </td>
                        )}

                        {/*// to compare */}
                        {showAttach && (
                          <td
                            key={`td-99`}
                            className="whitespace-nowrap"
                            width="130px"
                          >
                            <div className="text-center">
                              {/*{console.log("COMPARE", compare.data)}*/}
                              {/*{console.log("COMPARE", compare.fields[0], compare.fields[1], compare.id, compare?.data.find(x => x[compare.fields[0]] === item.id && x[compare.fields[1]] === compare.id))}*/}
                              {compare?.data?.find(
                                (x) =>
                                  x[compare.fields[0]] === item.id &&
                                  x[compare.fields[1]] === compare.id
                              ) ? (
                                <ButtonComponent
                                  icon={
                                    <XIcon
                                      className="h-3 w-3"
                                      aria-hidden="true"
                                    />
                                  }
                                  action={() =>
                                    handleDelete(
                                      compare?.data.find(
                                        (x) =>
                                          x[compare.fields[0]] === item.id &&
                                          x[compare.fields[1]] === compare.id
                                      ).id
                                    )
                                  }
                                  bg="white"
                                  color="black"
                                  border="black-500"
                                  classname="px-1 py-1"
                                />
                              ) : (
                                <ButtonComponent
                                  icon={
                                    <PlusIcon
                                      className="h-3 w-3"
                                      aria-hidden="true"
                                    />
                                  }
                                  action={() => handleEdit(item)}
                                  bg="blue"
                                  classname="px-1 py-1"
                                />
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      className="px-6 py-4 whitespace-nowrap text-gray-900"
                      colSpan={table.length}
                    >
                      Não há registros
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
